import React from 'react';
import {
  LegSeatInformation,
  SeatsInCoach
} from '../models/legs-seat-information';
import FONTS from '../styles/fonts';

interface SeatReservationsProps {
  coach: string;
  seat: string[];
}

interface SharedSeatsProps {
  legsSeatInformation: LegSeatInformation;
  departStation?: string;
  arriveStation?: string;
  shouldDisplayLegInformation: boolean;
}

const SeatReservations: React.FC<SeatReservationsProps> = ({ coach, seat }) => (
  <p css={{ margin: 0 }}>
    Coach {coach}, Seats {seat.join(', ')}
  </p>
);

const SharedSeatsInformation: React.FC<SharedSeatsProps> = ({
  legsSeatInformation,
  departStation,
  arriveStation,
  shouldDisplayLegInformation
}) => (
  <div>
    {shouldDisplayLegInformation ? (
      <p css={{ margin: '0.25rem 0 0' }}>
        {departStation} to {arriveStation}:
      </p>
    ) : null}
    <div css={[shouldDisplayLegInformation ? FONTS.book : FONTS.bold]}>
      {legsSeatInformation.seatInformation.map(
        (seatsInCoach: SeatsInCoach, index: number) => (
          <SeatReservations
            coach={seatsInCoach.coach}
            seat={seatsInCoach.seats}
            key={index}
          />
        )
      )}
    </div>
  </div>
);

export default SharedSeatsInformation;
