import React from 'react';
import COLORS from '../styles/colors';
import FONTS from '../styles/fonts';
import '../styles/font-face.css';

interface WhatsNextButtonProps {
  dynamicLinkUrl: string;
  displaySeatMessage?: boolean;
}

export const WhatsNextButton: React.FC<WhatsNextButtonProps> = ({
  dynamicLinkUrl,
  displaySeatMessage
}) => (
  <div>
    <button
      id="whatsNextButton"
      css={{
        padding: '0.41rem 0.2rem',
        width: '100%',
        fontSize: '0.875rem',
        background: COLORS.lnerRed,
        color: COLORS.white,
        borderRadius: '28px',
        borderStyle: 'none',
        margin: 0
      }}
      onClick={() => window.open(dynamicLinkUrl)}
    >
      <p css={[FONTS.book, { margin: '0.5rem 0' }]}>
        {displaySeatMessage ? 'Book your seat' : 'Book your journey'}
      </p>
    </button>
  </div>
);
