import React from 'react';
import COLORS from '../styles/colors';

interface TicketInfoTrainProps {
  isOutbound: boolean;
}

const getTrainIconTransform = (isFlipped: boolean) =>
  isFlipped ? 'scale(-1, 1) translate(-23, 0)' : 'translate(1)';

const TicketInfoTrainIcon: React.FC<TicketInfoTrainProps> = ({
  isOutbound
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1.5rem"
    height="1.5rem"
    viewBox="0 0 24 24"
  >
    <title>{isOutbound ? 'Outbound Train' : 'Inbound Train'}</title>

    <g
      fill="none"
      fillRule="evenodd"
      transform={getTrainIconTransform(!isOutbound)}
    >
      <path d="M-1 0H23V24H-1z" />
      <path
        fill={COLORS.doveGray}
        d="M19 17c.513 0 .936.386.993.883L20 18H1v-1h18zM9.333 6c1.656 0 3.17.38 6.06 2.493 2.89 2.114 3.628 2.893 4.607 4.03.578.67 1.002 1.227 1.002 2.117 0 .89-.819 1.36-1.22 1.36h-3.868c-.206.583-.761 1-1.414 1-.653 0-1.208-.417-1.414-1h-2.172c-.206.583-.761 1-1.414 1-.653 0-1.208-.417-1.414-1H1.5c-.276 0-.5-.224-.5-.5v-9c0-.276.224-.5.5-.5h7.833zM7.5 8h-4c-.276 0-.5.224-.5.5v2c0 .276.224.5.5.5h4c.276 0 .5-.224.5-.5v-2c0-.276-.224-.5-.5-.5zm3.548 0H10.5c-.276 0-.5.224-.5.5v2c0 .276.224.5.5.5h3.957c.16 0 .311-.077.405-.207.162-.223.112-.536-.111-.698l-2.236-1.62c-.427-.309-.94-.475-1.467-.475z"
      />
    </g>
  </svg>
);

export default TicketInfoTrainIcon;
