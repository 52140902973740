import React from 'react';
import COLORS from '../styles/colors';
import FONTS from '../styles/fonts';
import TicketInfoSeat from '../icons/ticket-info-seat';
import LegStationInformation from '../models/legs-station-information';
import { LegSeatInformation } from '../models/legs-seat-information';
import SharedSeatsInformation from './ticket-info-shared-seat-information';

interface TicketInfoSharedSeatsProps {
  legsSeatInformation: LegSeatInformation[];
  legs: LegStationInformation[];
}

const TicketInfoSharedSeats: React.FC<TicketInfoSharedSeatsProps> = ({
  legsSeatInformation,
  legs
}) => {
  const isDirectJourney = legs.length <= 1;

  return (
    <div
      css={{
        borderTop: `1pt solid ${COLORS.mercury}`,
        display: 'flex',
        flexDirection: 'row',
        color: COLORS.forestGreen,
        fontSize: '0.75rem',
        margin: '1rem 0 0.5rem',
        paddingTop: '1rem'
      }}
    >
      <div css={{ marginRight: '0.25rem' }}>
        <TicketInfoSeat />
      </div>
      <div>
        <p
          css={[FONTS.book, { margin: isDirectJourney ? '0 0 0.25rem 0' : 0 }]}
        >
          Your friend has shared their seats
        </p>
        <div css={FONTS.bold}>
          {legsSeatInformation.map(
            (seatInformationForOneLeg: LegSeatInformation, index: number) => (
              <SharedSeatsInformation
                legsSeatInformation={seatInformationForOneLeg}
                arriveStation={
                  legs[seatInformationForOneLeg.leg]?.arriveStationName
                }
                departStation={
                  legs[seatInformationForOneLeg.leg]?.departStationName
                }
                key={index}
                shouldDisplayLegInformation={!isDirectJourney}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default TicketInfoSharedSeats;
