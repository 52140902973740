import React from 'react';
import FONTS from '../styles/fonts';
import '../styles/font-face.css';

const WhatsNextBannerSeatImageSvg: React.FC = () => (
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="12.5rem"
    height="2.875rem"
    css={{
      maxWidth: '100%',
      maxHeight: '100%'
    }}
    viewBox="0 0 212 46"
    version="1.1"
  >
    <title>Book your seat</title>
    <defs>
      <path
        d="M10,0 L30,0 C35.5228475,-1.01453063e-15 40,4.4771525 40,10 L40,36.6666667 C40,38.5076158 38.5076158,40 36.6666667,40 L3.33333333,40 C1.49238417,40 6.97771481e-15,38.5076158 0,36.6666667 L0,10 C-6.76353751e-16,4.4771525 4.4771525,1.01453063e-15 10,0 Z"
        id="path-1"
      />
      <filter
        x="-10.0%"
        y="-10.0%"
        width="125.0%"
        height="125.0%"
        filterUnits="objectBoundingBox"
        id="filter-2"
      >
        <feOffset dx="1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation="1.5"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.15 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
      <path
        d="M3.33333333,3.02868841e-13 L36.6666667,3.02868841e-13 C38.5076158,3.02530664e-13 40,1.49238417 40,3.33333333 L40,4.16666667 C40,6.46785312 38.1345198,8.33333333 35.8333333,8.33333333 L4.16666667,8.33333333 C1.86548021,8.33333333 1.17521365e-13,6.46785312 1.17239551e-13,4.16666667 L1.17239551e-13,3.33333333 C1.170141e-13,1.49238417 1.49238417,3.03207018e-13 3.33333333,3.02868841e-13 Z"
        id="path-3"
      />
      <path
        d="M10,0 L30,0 C35.5228475,-1.01453063e-15 40,4.4771525 40,10 L40,36.6666667 C40,38.5076158 38.5076158,40 36.6666667,40 L3.33333333,40 C1.49238417,40 6.97771481e-15,38.5076158 0,36.6666667 L0,10 C-6.76353751e-16,4.4771525 4.4771525,1.01453063e-15 10,0 Z"
        id="path-4"
      />
      <filter
        x="-10.0%"
        y="-10.0%"
        width="125.0%"
        height="125.0%"
        filterUnits="objectBoundingBox"
        id="filter-5"
      >
        <feOffset dx="1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation="1.5"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.15 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
      <path
        d="M10,0 L30,0 C35.5228475,-1.01453063e-15 40,4.4771525 40,10 L40,36.6666667 C40,38.5076158 38.5076158,40 36.6666667,40 L3.33333333,40 C1.49238417,40 6.97771481e-15,38.5076158 0,36.6666667 L0,10 C-6.76353751e-16,4.4771525 4.4771525,1.01453063e-15 10,0 Z"
        id="path-6"
      />
      <filter
        x="-10.0%"
        y="-10.0%"
        width="125.0%"
        height="125.0%"
        filterUnits="objectBoundingBox"
        id="filter-7"
      >
        <feOffset dx="1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation="1.5"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.15 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
    </defs>
    <g
      id="Share-(Intern)"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="LM-2582-Webpage---Shared-Mobile---Single"
        transform="translate(-84.000000, -594.000000)"
      >
        <g id="Group-6" transform="translate(16.000000, 523.000000)">
          <g id="Group-4" transform="translate(16.000000, 58.000000)">
            <g id="choose-seating" transform="translate(52.000000, 17.000000)">
              <g
                id="iOS/UI-Modules/Seatmap/Seat/Available/Back"
                transform="translate(112.000000, 0.000000)"
              >
                <g
                  id="Rectangle-Copy-3"
                  transform="translate(20.000000, 20.000000) scale(1, -1) translate(-20.000000, -20.000000) "
                >
                  <use
                    fill="black"
                    fillOpacity="1"
                    filter="url(#filter-2)"
                    xlinkHref="#path-1"
                  />
                  <use fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-1" />
                </g>
                <path
                  d="M3.33333333,0 L36.6666667,0 C38.5076158,-3.38176876e-16 40,1.49238417 40,3.33333333 L40,4.16666667 C40,6.46785312 38.1345198,8.33333333 35.8333333,8.33333333 L4.16666667,8.33333333 C1.86548021,8.33333333 9.79814402e-14,6.46785312 9.76996262e-14,4.16666667 L9.76996262e-14,3.33333333 C9.83623533e-14,1.49238417 1.49238417,-1.05912334e-16 3.33333333,0 Z"
                  id="Rectangle"
                  fill="#EFEFEF"
                  fillRule="evenodd"
                />
                <path
                  d="M30,0.416666667 L10,0.416666667 C7.35363557,0.416666667 4.95780224,1.48931779 3.22356001,3.22356001 C1.48931779,4.95780224 0.416666667,7.35363557 0.416666667,10 L0.416666667,36.6666667 C0.416666667,37.4720819 0.743125703,38.2012486 1.27093855,38.7290614 C1.79875141,39.2568743 2.52791807,39.5833333 3.33333333,39.5833333 L36.6666667,39.5833333 C37.4720819,39.5833333 38.2012486,39.2568743 38.7290614,38.7290614 C39.2568743,38.2012486 39.5833333,37.4720819 39.5833333,36.6666667 L39.5833333,10 C39.5833333,7.35363557 38.5106822,4.95780224 36.77644,3.22356001 C35.0421978,1.48931779 32.6463644,0.416666667 30,0.416666667 Z"
                  id="Rectangle-Copy-4"
                  stroke="#A6A6A6"
                  strokeWidth="0.833333333"
                  transform="translate(20.000000, 20.000000) scale(1, -1) translate(-20.000000, -20.000000) "
                />
                <text
                  id="03"
                  css={FONTS.bold}
                  fontSize="13.3333333"
                  fontWeight="bold"
                  fill="#382F2D"
                >
                  <tspan x="11.0733333" y="25.5">
                    03
                  </tspan>
                </text>
              </g>
              <g id="iOS/UI-Modules/Seatmap/Seat/Distant/Back-Copy">
                <g id="Group-5" fill="#258825">
                  <path
                    d="M10,0 L30,0 C35.5228475,-1.01453063e-15 40,4.4771525 40,10 L40,36.6666667 C40,38.5076158 38.5076158,40 36.6666667,40 L3.33333333,40 C1.49238417,40 3.42500113e-15,38.5076158 0,36.6666667 L0,10 C-6.76353751e-16,4.4771525 4.4771525,1.01453063e-15 10,0 Z"
                    id="Rectangle-Copy-4"
                    transform="translate(20.000000, 20.000000) scale(1, -1) translate(-20.000000, -20.000000) "
                  />
                </g>
                <g id="Rectangle">
                  <use fill="#FFFFFF" xlinkHref="#path-3" />
                  <use fillOpacity="0.7" fill="#258825" xlinkHref="#path-3" />
                </g>
              </g>
              <g
                id="iOS/UI-Modules/Seatmap/Seat/Chosen/Back"
                transform="translate(56.000000, 0.000000)"
              >
                <g
                  id="Rectangle-Copy-3"
                  transform="translate(20.000000, 20.000000) scale(1, -1) translate(-20.000000, -20.000000) "
                >
                  <use
                    fill="black"
                    fillOpacity="1"
                    filter="url(#filter-5)"
                    xlinkHref="#path-4"
                  />
                  <use fill="#D01936" fillRule="evenodd" xlinkHref="#path-4" />
                </g>
                <text
                  id="03"
                  css={FONTS.bold}
                  fontSize="13.3333333"
                  fontWeight="bold"
                />
                <path
                  d="M3.33333333,0 L36.6666667,0 C38.5076158,-3.38176876e-16 40,1.49238417 40,3.33333333 L40,4.16666667 C40,6.46785312 38.1345198,8.33333333 35.8333333,8.33333333 L4.16666667,8.33333333 C1.86548021,8.33333333 9.79814402e-14,6.46785312 9.76996262e-14,4.16666667 L9.76996262e-14,3.33333333 C9.83623533e-14,1.49238417 1.49238417,-1.05912334e-16 3.33333333,0 Z"
                  id="Rectangle"
                  fill="#E15A70"
                  fillRule="evenodd"
                />
              </g>
              <g
                id="Brand/Icon-Family/person"
                transform="translate(66.000000, 12.000000)"
                fill="#FFFFFF"
              >
                <path
                  d="M14.0763592,10.0279856 C15.6515621,10.663175 16.6666667,11.6569294 16.6666667,12.774681 C16.6666667,14.6922933 13.681968,17.5 10,17.5 C6.31803198,17.5 3.33333333,14.6922933 3.33333333,12.774681 C3.33333333,11.6569294 4.34843788,10.663175 5.92364084,10.0279856 C6.9503567,11.1092094 8.39744157,11.7848755 10,11.7848755 C11.6025584,11.7848755 13.0496433,11.1094068 14.0763592,10.0279856 Z M10,1.66666667 C12.4589103,1.66666667 14.4522509,3.66654799 14.4522509,6.13352662 C14.4522509,8.60050526 12.4589103,10.6003866 10,10.6003866 C7.54108971,10.6003866 5.54774908,8.60050526 5.54774908,6.13352662 C5.54774908,3.66654799 7.54108971,1.66666667 10,1.66666667 Z"
                  id="Mask"
                />
              </g>
              <g
                id="iOS/UI-Modules/Seatmap/Seat/Available/Back"
                transform="translate(168.000000, 0.000000)"
              >
                <g
                  id="Rectangle-Copy-3"
                  transform="translate(20.000000, 20.000000) scale(1, -1) translate(-20.000000, -20.000000) "
                >
                  <use
                    fill="black"
                    fillOpacity="1"
                    filter="url(#filter-7)"
                    xlinkHref="#path-6"
                  />
                  <use fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-6" />
                </g>
                <path
                  d="M3.33333333,0 L36.6666667,0 C38.5076158,-3.38176876e-16 40,1.49238417 40,3.33333333 L40,4.16666667 C40,6.46785312 38.1345198,8.33333333 35.8333333,8.33333333 L4.16666667,8.33333333 C1.86548021,8.33333333 9.79814402e-14,6.46785312 9.76996262e-14,4.16666667 L9.76996262e-14,3.33333333 C9.83623533e-14,1.49238417 1.49238417,-1.05912334e-16 3.33333333,0 Z"
                  id="Rectangle"
                  fill="#EFEFEF"
                  fillRule="evenodd"
                />
                <path
                  d="M30,0.416666667 L10,0.416666667 C7.35363557,0.416666667 4.95780224,1.48931779 3.22356001,3.22356001 C1.48931779,4.95780224 0.416666667,7.35363557 0.416666667,10 L0.416666667,36.6666667 C0.416666667,37.4720819 0.743125703,38.2012486 1.27093855,38.7290614 C1.79875141,39.2568743 2.52791807,39.5833333 3.33333333,39.5833333 L36.6666667,39.5833333 C37.4720819,39.5833333 38.2012486,39.2568743 38.7290614,38.7290614 C39.2568743,38.2012486 39.5833333,37.4720819 39.5833333,36.6666667 L39.5833333,10 C39.5833333,7.35363557 38.5106822,4.95780224 36.77644,3.22356001 C35.0421978,1.48931779 32.6463644,0.416666667 30,0.416666667 Z"
                  id="Rectangle-Copy-4"
                  stroke="#A6A6A6"
                  strokeWidth="0.833333333"
                  transform="translate(20.000000, 20.000000) scale(1, -1) translate(-20.000000, -20.000000) "
                />
                <text
                  id="03"
                  css={FONTS.bold}
                  fontSize="13.3333333"
                  fontWeight="bold"
                  fill="#382F2D"
                >
                  <tspan x="11.0733333" y="25.5">
                    04
                  </tspan>
                </text>
              </g>
              <g
                id="Brand/Icon-Family/tick"
                transform="translate(8.000000, 9.000000)"
                fill="#FFFFFF"
              >
                <polygon
                  id="Path"
                  points="8.5788 20 2 13.1456553 3.9354 11.1291936 8.5788 15.9670765 20.0648 4 22 6.01646176"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default WhatsNextBannerSeatImageSvg;
