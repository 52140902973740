import React, { useEffect, useState } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { isIPad13, isBrowser } from 'react-device-detect';
import {
  WhatsNextBanner,
  WhatsNextProps
} from '../components/whats-next-banner';
import TicketInfoCard from '../components/ticket-info-complete-card';
import SharedJourneyBanner from '../components/shared-journey-banner';
import AppStoresContainer from '../components/app-stores/app-stores-container';
import parseJourneyId from '../helpers/journey-id-parse';
import parseSeatId from '../helpers/seat-id-parse';
import queryString from 'query-string';
import buildStationMap from '../builders/build-station-map';
import LoadingSpinner from '../components/loading-spinner';
import CustomErrorMessage from '../components/custom-error-message';
import buildDynamicLink from '../builders/build-dynamic-links';
import { useApiBaseUrl, useBaseUrl } from '../helpers/url-hooks';
import GoogleTagManager from '../analytics/google-tag-manager';
import Journey from '../models/journey';

enum PageState {
  UNMOUNTED, // To avoid content mismatch during hydration (due to device detect)
  LOADING,
  LOADED,
  API_ERROR,
  URL_ERROR
}

const IndexPage = () => (
  <>
    <GoogleTagManager />
    <Layout>
      <SEO title="Share a Journey" />
      <IndexPageBody />
    </Layout>
  </>
);

const WhatsNextSection: React.FC<WhatsNextProps> = (props) =>
  isBrowser && !isIPad13 ? (
    <AppStoresContainer />
  ) : (
    <WhatsNextBanner {...props} />
  );

const IndexPageBody = () => {
  const outboundJourneyId = getQueryStringValue('outJourneyId')!;
  const outboundSeats = getQueryStringValue('outSeats');
  const inboundJourneyId = getQueryStringValue('inJourneyId');
  const inboundSeats = getQueryStringValue('inSeats');
  const openReturn = getQueryStringValue('openRet');

  const outboundSharedSeats = parseSeatId(outboundSeats);
  const inboundSharedSeats = parseSeatId(inboundSeats);

  const initialJourney = null as Journey | null;
  const [outboundJourney, updateOutboundJourney] = useState(initialJourney);
  const [inboundJourney, updateInboundJourney] = useState(initialJourney);
  const [pageState, updatePageState] = useState(PageState.UNMOUNTED);
  const url = useBaseUrl();
  const apiUrl = useApiBaseUrl();

  useEffect(() => {
    updatePageState(PageState.LOADING);
    fetch(apiUrl + 'v1/stations')
      .then((response) => response.json())
      .then((stationData) => {
        const stationMap = buildStationMap(stationData);
        const newOutboundJourney = parseJourneyId(
          outboundJourneyId,
          stationMap
        );

        updateOutboundJourney(newOutboundJourney);
        updateInboundJourney(parseJourneyId(inboundJourneyId, stationMap));
        updatePageState(
          newOutboundJourney ? PageState.LOADED : PageState.URL_ERROR
        );
      })
      .catch(() => updatePageState(PageState.API_ERROR));
  }, []);

  const hasSeats = !!(outboundSeats || inboundSeats);

  const dynamicLinkUrl = buildDynamicLink(
    url,
    'ticket-search-landing-page/',
    outboundJourneyId,
    outboundSeats,
    inboundJourneyId,
    inboundSeats,
    openReturn === 'true'
  );

  const searchFormDeepLinkUrl = url + 'mobile/search';

  if (pageState === PageState.UNMOUNTED) {
    return null;
  }

  if (pageState === PageState.LOADING) {
    return <LoadingSpinner />;
  }

  if (pageState === PageState.URL_ERROR || pageState === PageState.API_ERROR) {
    const errorMessage =
      pageState === PageState.URL_ERROR
        ? 'This looks like an invalid journey'
        : 'We are experiencing technical difficulties';
    const linkUrl =
      pageState === PageState.URL_ERROR
        ? searchFormDeepLinkUrl
        : dynamicLinkUrl;

    return (
      <>
        <CustomErrorMessage errorMessage={errorMessage} />
        <WhatsNextSection
          dynamicLinkUrl={linkUrl}
          hasSeats={hasSeats}
          errored
        />
      </>
    );
  }

  return (
    <>
      <SharedJourneyBanner message="Shared journey" />
      <TicketInfoCard
        outboundJourney={outboundJourney!}
        outboundLegsSeatInformation={outboundSharedSeats}
        inboundJourney={inboundJourney}
        inboundLegsSeatInformation={inboundSharedSeats}
        isOpenReturn={openReturn === 'true'}
      />
      <WhatsNextSection dynamicLinkUrl={dynamicLinkUrl} hasSeats={hasSeats} />
    </>
  );
};

const getQueryStringValue = (parameter: string): string | undefined => {
  if (typeof window !== 'undefined') {
    const queryParams = queryString.parseUrl(window.location.href);
    const queryParam = queryParams.query[parameter];
    if (Array.isArray(queryParam)) {
      return queryParam.length > 0 ? queryParam[0] : undefined;
    }
    return queryParam as string;
  }
  return undefined;
};

export default IndexPage;
