import React from 'react';
import COLORS from '../styles/colors';
import FONTS from '../styles/fonts';
import SharedJourneyIcon from '../icons/shared-journey-icon';
import MQ from '../styles/breakpoints';

type SharedJourneyBannerProps = {
  message: string;
};

const SharedJourneyBanner: React.FC<SharedJourneyBannerProps> = ({
  message
}) => (
  <div
    role="img"
    aria-label={message}
    css={MQ({
      display: 'flex',
      alignItems: 'center',
      paddingTop: ['1.775rem', '2.9rem'],
      paddingBottom: ['1.5875rem', '1.4rem']
    })}
  >
    <SharedJourneyIcon />
    <div
      css={[
        FONTS.bold,
        MQ({
          height: '100%',
          verticalAlign: 'middle',
          color: COLORS.lnerRed,
          paddingLeft: '0.3rem',
          fontSize: ['0.875rem', '1rem']
        })
      ]}
    >
      {message}
    </div>
  </div>
);

export default SharedJourneyBanner;
