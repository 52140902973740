import React from 'react';
import TicketInfoHead from './ticket-info-head';
import TicketInfoBody from './ticket-info-body';
import { LegSeatInformation } from '../models/legs-seat-information';
import Journey from '../models/journey';
import TicketInfoOpenReturn from './ticket-info-open-return';

interface TicketInfoCardProps {
  outboundJourney: Journey;
  inboundJourney: Journey | null;
  outboundLegsSeatInformation: LegSeatInformation[];
  inboundLegsSeatInformation: LegSeatInformation[];
  isOpenReturn: Boolean;
}

const TicketInfoCard: React.FC<TicketInfoCardProps> = ({
  outboundJourney,
  inboundJourney,
  outboundLegsSeatInformation,
  inboundLegsSeatInformation,
  isOpenReturn
}) => {
  const isReturnJourney = inboundJourney !== null;

  return (
    <div
      css={{
        boxShadow: '0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.1)',
        margin: '0',
        borderRadius: '8pt'
      }}
    >
      <TicketInfoHead
        departureStation={outboundJourney.originStationName}
        arrivalStation={outboundJourney.destinationStationName}
      />
      <TicketInfoBody
        journey={outboundJourney}
        isOutbound={true}
        isBottomCard={!isReturnJourney && !isOpenReturn}
        legsSeatInformation={outboundLegsSeatInformation}
      />
      {isReturnJourney && (
        <TicketInfoBody
          journey={inboundJourney!}
          isOutbound={false}
          isBottomCard={!isOpenReturn}
          legsSeatInformation={inboundLegsSeatInformation}
        />
      )}
      {isOpenReturn && <TicketInfoOpenReturn />}
    </div>
  );
};

export default TicketInfoCard;
