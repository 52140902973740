import React from 'react';

const TicketInfoSeat: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1.25rem"
    height="1.25rem"
    viewBox="0 0 24 24"
  >
    <title>Seat Icon</title>

    <g fill="none" fillRule="evenodd">
      <path d="M0 0H24V24H0z" />
      <path
        fill="#258825"
        d="M18.35 12.566l-.01-.003s-.08-.02-.198-.026c-.044-.005-.081-.016-.13-.016h-4.53s-.051 0-.126.012c-.045-.002-.09-.001-.14.003l-3.785.323-.448-1.775h5.653s.88 0 .88-.86v-.01s0-.858-.88-.858H8.544L7.75 6.192c-.062-.247-.094-.5-.095-.754l-.013-2.15S7.642 2 6.322 2C6.321 2 5 2 5 3.288v1.933c0 .21.027.42.08.624l2.506 9.604s.077.203.252.412c.186.267.507.498 1.066.498h1.833V22h4.483v-5.64h1.797l.018-.001h.473c.265.012.726-.055.885-.633l.58-2.109s.227-.83-.624-1.051"
      />
    </g>
  </svg>
);

export default TicketInfoSeat;
