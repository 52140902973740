import React from 'react';

const LnerLogo: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1.8rem"
    height="0.9rem"
    viewBox="0 0 30 12"
  >
    <title>LNER Logo</title>
    <path
      fill="#CE0E2D"
      fillRule="evenodd"
      d="M6.547.06l5.41 6.952V2.161h1.75v9.815l-5.41-6.945v5.01h-1.75V.06zm15.173 2.1v1.543h-4.317v1.598h3.799v1.543h-3.8v1.654h4.375v1.542h-6.136V2.16h6.079zm-20.81 0V9.22h4.535v.821H0V2.16h.91zm25.889 0c.99 0 1.784.294 2.29.789.392.383.622.934.622 1.553v.023c0 1.305-.92 2.07-2.187 2.296l2.475 3.22h-1.117l-2.337-3.062h-2.302v3.061h-.91V2.16H26.8zm-.07.823h-2.486v3.196h2.475c1.209 0 2.072-.607 2.072-1.62v-.023c0-.968-.76-1.553-2.06-1.553z"
    />
  </svg>
);

export default LnerLogo;
