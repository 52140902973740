import React from 'react';
import COLORS from '../styles/colors';
import '../styles/font-face.css';

const SharedJourneyIcon: React.FC = () => (
  <svg
    width="1.5rem"
    height="1.5rem"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill={COLORS.lnerRed}
  >
    <title>Share Icon</title>
    <g data-name="Share Icon">
      <path
        fillRule="evenodd"
        fill={COLORS.lnerRed}
        d="M17 15.4c-.633 0-1.2.25-1.633.642l-5.942-3.459c.042-.191.075-.383.075-.583 0-.2-.033-.392-.075-.583L15.3 7.992c.45.416 1.042.675 1.7.675 1.383 0 2.5-1.117 2.5-2.5 0-1.384-1.117-2.5-2.5-2.5s-2.5 1.116-2.5 2.5c0 .2.033.391.075.583L8.7 10.175C8.25 9.758 7.658 9.5 7 9.5c-1.383 0-2.5 1.117-2.5 2.5s1.117 2.5 2.5 2.5c.658 0 1.25-.258 1.7-.675l5.933 3.467c-.041.175-.066.358-.066.541 0 1.342 1.091 2.434 2.433 2.434 1.342 0 2.433-1.092 2.433-2.434 0-1.341-1.091-2.433-2.433-2.433z"
      ></path>
    </g>
  </svg>
);

export default SharedJourneyIcon;
