import {
  LegSeatInformation,
  SeatsInCoach
} from '../models/legs-seat-information';

const parseSeatId = (sharedSeatsString?: string): LegSeatInformation[] => {
  const seatIdsPerLeg = sharedSeatsString?.split('|');

  let legsSeatInformation: LegSeatInformation[] = [];

  seatIdsPerLeg?.forEach((seatIdsPerLeg: string, index: number) => {
    if (seatIdsPerLeg !== '') {
      const seatInformationForLeg = getSeatInformationForLeg(
        seatIdsPerLeg,
        index
      );
      legsSeatInformation.push(seatInformationForLeg);
    }
  });

  return legsSeatInformation;
};

const getSeatInformationForLeg = (
  seatIdsPerLeg: string,
  index: number
): LegSeatInformation => {
  const seatsPerLeg = seatIdsPerLeg.split(',');
  let seatsInCoachPerLeg: SeatsInCoach[] = [];
  let coachesInLeg: string[] = [];

  seatsPerLeg.forEach((coachSeatCombination: string) => {
    const coachLetter: string = coachSeatCombination.slice(0, 1);
    const seatNumber: string = coachSeatCombination.slice(1);

    if (coachesInLeg.includes(coachLetter)) {
      seatsInCoachPerLeg[coachesInLeg.indexOf(coachLetter)].seats.push(
        seatNumber
      );
    } else {
      seatsInCoachPerLeg.push({
        coach: coachLetter,
        seats: [seatNumber]
      });
      coachesInLeg.push(coachLetter);
    }
  });

  return {
    leg: index,
    seatInformation: seatsInCoachPerLeg
  };
};

export default parseSeatId;
