import React from 'react';
import COLORS from '../styles/colors';
import FONTS from '../styles/fonts';
import MQ from '../styles/breakpoints';

interface TicketInfoHeadProps {
  departureStation: string;
  arrivalStation: string;
}

const TicketInfoHead: React.FC<TicketInfoHeadProps> = ({
  departureStation,
  arrivalStation
}) => (
  <div
    css={MQ({
      padding: ['1.1875rem 1rem 0.985rem', '1.25rem 1.5rem 0.9825rem'],
      justifyContent: 'center',
      color: COLORS.dune,
      background: COLORS.white,
      overflow: 'hidden',
      borderRadius: '8pt 8pt 0 0',
      borderBottom: `1.5pt dashed ${COLORS.mercury}`
    })}
  >
    <p
      css={[
        FONTS.bold,
        MQ({
          margin: 0,
          fontSize: ['0.875rem', '1rem'],
          lineHeight: '1rem'
        })
      ]}
    >
      {departureStation} to {arrivalStation}
    </p>
  </div>
);

export default TicketInfoHead;
