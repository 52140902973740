import React from 'react';
import MQ from '../styles/breakpoints';
import COLORS from '../styles/colors';
import ShareErrorIcon from '../icons/share-error';
import FONTS from '../styles/fonts';

interface CustomErrorMessageProps {
  errorMessage: string;
}

const CustomErrorMessage: React.FC<CustomErrorMessageProps> = ({
  errorMessage
}) => (
  <div
    css={MQ({
      display: 'flex',
      justifyContent: 'flex-start',
      marginTop: ['2.5rem', '4rem'],
      marginLeft: '1rem',
      marginRight: '1rem',
      marginBottom: [0, '2rem'],
      flexDirection: ['column', 'row'],
      borderBottom: ['none', `1pt solid ${COLORS.mercury}`],
      paddingBottom: ['1.5rem', '4rem']
    })}
  >
    <div
      css={MQ({
        display: 'flex',
        borderBottom: [`1pt solid ${COLORS.mercury}`, 'none'],
        justifyContent: 'center',
        paddingBottom: ['1.875rem', 0]
      })}
    >
      <ShareErrorIcon />
    </div>
    <div
      css={MQ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginLeft: ['0rem', '2rem'],
        paddingTop: ['1.875rem', 0]
      })}
    >
      <p
        css={[
          FONTS.bold,
          MQ({
            margin: '0 0 1rem',
            fontSize: ['1.25rem', '1.5rem'],
            lineHeight: ['1.25rem', '1.5rem'],
            color: COLORS.dune
          })
        ]}
      >
        Sorry. There's a problem
      </p>
      <p
        css={[
          FONTS.book,
          MQ({
            margin: 0,
            fontSize: ['0.875rem', '1rem'],
            color: COLORS.dune
          })
        ]}
      >
        {errorMessage}
      </p>
    </div>
  </div>
);

export default CustomErrorMessage;
