import React from 'react';
import COLORS from '../styles/colors';
import FONTS from '../styles/fonts';
import { WhatsNextButton } from './whats-next-button';
import WhatsNextSeatImage from './whats-next-seat-image';
import MQ from '../styles/breakpoints';

export interface WhatsNextProps {
  dynamicLinkUrl: string;
  hasSeats?: boolean;
  errored?: boolean;
}

const installAppMessage = (errored?: boolean, hasSeats?: boolean) => {
  if (errored) {
    return 'Download the LNER app to book a new journey';
  }

  return hasSeats
    ? 'Download the LNER app to choose a seat near your friend'
    : 'Download the LNER app to book the same train as your friend';
};

export const WhatsNextBanner: React.FC<WhatsNextProps> = ({
  dynamicLinkUrl,
  hasSeats,
  errored
}) => (
  <div
    css={MQ({
      padding: ['1.25rem 1rem 1.5rem', '1.25rem 1.5rem 1.5rem'],
      maxWidth: 720,
      background: COLORS.white,
      margin: '1.33rem 0rem',
      borderRadius: '8pt',
      boxShadow: '0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.1)',
      justifyContent: 'center',
      color: COLORS.dune
    })}
  >
    <p css={[FONTS.bold, MQ({ marginTop: 0, fontSize: ['0.875rem', '1rem'] })]}>
      What's next
    </p>

    {errored ? null : <WhatsNextSeatImage />}

    <p css={[FONTS.book, { marginBottom: '1.5rem', fontSize: '0.875rem' }]}>
      {installAppMessage(errored, hasSeats)}
    </p>

    <WhatsNextButton
      dynamicLinkUrl={dynamicLinkUrl}
      displaySeatMessage={!errored || hasSeats}
    />
  </div>
);
