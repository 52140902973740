import _ from 'lodash';

function buildDynamicLink(
  domain: string,
  path: string,
  outJourneyId: string,
  outSeats: string = '',
  inJourneyId: string = '',
  inSeats: string = '',
  openRet: boolean = false
) {
  const fireBaseURL = process.env.GATSBY_LNER_FIREBASE_URL;
  const androidApp = process.env.GATSBY_LNER_ANDROID_APP;
  const iosApp = process.env.GATSBY_LNER_IOS_APP;
  const appStoreApplicationId = process.env.GATSBY_LNER_APP_STORE_ID;
  const deepLink = buildDeepLink(
    domain,
    path,
    outJourneyId,
    outSeats,
    inJourneyId,
    inSeats,
    openRet
  );
  let output = `${fireBaseURL}?link=${deepLink}&apn=${androidApp}&isi=${appStoreApplicationId}&ibi=${iosApp}`;
  return output;
}

function buildDeepLink(
  domain: string,
  path: string,
  outJourneyId: string,
  outSeats: string,
  inJourneyId: string,
  inSeats: string,
  openRet: boolean
) {
  const rawParams = {
    outJourneyId: outJourneyId,
    outSeats: outSeats,
    inJourneyId: inJourneyId,
    inSeats: inSeats,
    openRet: openRet ? openRet.toString() : ''
  };

  let filteredParameters = _.pickBy(rawParams, (p) => p != null && p !== '');

  let parameters = new URLSearchParams(filteredParameters);

  // Encoding the parameters again because iOS won't be able to handle it otherwise
  // Android can handle both cases, so it should be unaffected
  const encodedParameters = encodeURIComponent(parameters.toString());
  return `${domain}${path}?${encodedParameters}`;
}

export default buildDynamicLink;
