import React from 'react';

const TicketInfoArrowRight: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1rem"
    height="1rem"
    viewBox="0 0 16 16"
  >
    <title>Right Arrow</title>
    <path
      fill="#440F11"
      fillRule="evenodd"
      d="M14.667 8.008L8.721 14 6.734 14 11.967 8.743 0.667 8.743 0.667 7.257 11.967 7.257 6.734 2 8.721 2z"
      transform="matrix(1 0 0 -1 0 16)"
    />
  </svg>
);

export default TicketInfoArrowRight;
