import React from 'react';
import COLORS from '../styles/colors';
import FONTS from '../styles/fonts';
import TicketInfoTrainIcon from '../icons/ticket-info-train-icon';
import MQ from '../styles/breakpoints';

const TicketInfoOpenReturn = () => {
  return (
    <div
      css={[
        FONTS.book,
        MQ({
          padding: ['0.75rem 1rem', '0.75rem 1.5rem'],
          background: COLORS.white,
          borderRadius: '0 0 8pt 8pt',
          borderTop: `1pt solid ${COLORS.mercury}`,
          color: COLORS.dune,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        })
      ]}
    >
      <TicketInfoTrainIcon isOutbound={false} />
      <p
        css={MQ({
          margin: ['0 0 0 0.5rem', '0 0 0 1.25rem'],
          fontSize: '0.875rem',
          color: COLORS.dune
        })}
      >
        Open return
      </p>
    </div>
  );
};

export default TicketInfoOpenReturn;
