import React from 'react';
import './loading-spinner.css';
import colors from '../styles/colors';

const LoadingSpinner: React.FC = () => (
  <div css={{ width: '100%' }}>
    <div
      css={{
        margin: 'auto',
        marginTop: '1rem',
        marginBottom: '1rem',
        border: `8px solid ${colors.wildSand}`,
        borderTopColor: colors.lnerRed,
        borderRadius: '50%',
        width: '3rem',
        height: '3rem',
        animation: 'spin 1s linear infinite'
      }}
    />
  </div>
);
export default LoadingSpinner;
