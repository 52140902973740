function buildStationMap(stationData: {
  stations: { crs: string; nlc: string; name: string }[];
}) {
  const newStationMap = new Map<string, string>();
  stationData.stations.forEach(
    (station: { crs: string; nlc: string; name: string }) => {
      if (station.crs) {
        newStationMap.set(station.crs, station.name);
      }
      if (station.nlc) {
        newStationMap.set(station.nlc, station.name);
      }
    }
  );
  return newStationMap;
}

export default buildStationMap;
