import { DateTime, Interval } from 'luxon';
import Journey from '../models/journey';
import LegStationInformation from '../models/legs-station-information';

const DATE_FORMAT_STRING = 'ccc d LLL';
const TIME_FORMAT_STRING = 'T';
const LNER_OPERATOR_CODE = 'GR';

const parseJourneyId = (
  journeyId: string | undefined,
  stationMap: Map<string, string>
): Journey | null => {
  if (typeof journeyId === 'undefined') {
    return null;
  }

  const elements: string[] = journeyId.split('|');

  if (elements.length < 7) {
    return null;
  }

  const originDateTime = convertToDateTime(elements[3], elements[4]);
  const destinationDateTime = convertToDateTime(elements[5], elements[6]);

  if (!originDateTime.isValid || !destinationDateTime.isValid) {
    return null;
  }

  const legs = convertToLegStationInformation(elements.slice(7), stationMap);
  if (legs === null) {
    return null;
  }

  const originStationName = stationMap.get(elements[1]);
  const destinationStationName = stationMap.get(elements[2]);

  if (!originStationName || !destinationStationName) {
    return null;
  }

  return {
    originStationName: originStationName,
    destinationStationName: destinationStationName,
    departDate: originDateTime.toFormat(DATE_FORMAT_STRING),
    departTime: originDateTime.toFormat(TIME_FORMAT_STRING),
    arriveTime: destinationDateTime.toFormat(TIME_FORMAT_STRING),
    duration: convertToDurationMessage(originDateTime, destinationDateTime),
    isLner: elements.includes(LNER_OPERATOR_CODE),
    numberOfChanges: legs.length === 0 ? 0 : legs.length - 1,
    legs: legs
  };
};

const convertToDateTime = (dateString: string, timeString: string) => {
  return DateTime.fromObject({
    year: 2000 + parseInt(dateString.slice(0, 2)),
    month: parseInt(dateString.slice(2, 4)),
    day: parseInt(dateString.slice(4)),
    hour: parseInt(timeString.slice(0, 2)),
    minute: parseInt(timeString.slice(2))
  });
};

const convertToLegStationInformation = (
  legs: string[],
  stationMap: Map<string, string>
): LegStationInformation[] | null => {
  let legsOutput = [];
  let i = 0;

  while (i < legs.length) {
    const departStationName = stationMap.get(legs[i + 1]);
    const arriveStationName = stationMap.get(legs[i + 2]);
    if (!departStationName || !arriveStationName) {
      return null;
    }

    const leg = { departStationName, arriveStationName };
    legsOutput.push(leg);
    legs[i] === 'TRIP' ? (i += 9) : (i += 5);
  }

  return legsOutput;
};

const convertToDurationMessage = (
  originDateTime: DateTime,
  destinationDateTime: DateTime
): string => {
  const duration = Interval.fromDateTimes(
    originDateTime,
    destinationDateTime
  ).toDuration(['hours', 'minutes']);
  return duration.hours === 0
    ? `${duration.minutes}m`
    : `${duration.hours}h ${duration.minutes}m`;
};

export default parseJourneyId;
