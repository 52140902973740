import React from 'react';
import COLORS from '../styles/colors';
import FONTS from '../styles/fonts';
import TicketInfoTrainIcon from '../icons/ticket-info-train-icon';
import TicketInfoArrowRight from '../icons/arrow-right';
import LnerLogo from '../icons/ticket-info-lner-logo';
import TicketInfoSharedSeats from './ticket-info-share-seat-banner';
import MQ from '../styles/breakpoints';
import { Interpolation } from '@emotion/core';
import { LegSeatInformation } from '../models/legs-seat-information';
import Journey from '../models/journey';

interface TicketInfoBodyProps {
  journey: Journey;
  isOutbound: boolean;
  isBottomCard: boolean;
  legsSeatInformation: LegSeatInformation[];
}

const TicketInfoBody: React.FC<TicketInfoBodyProps> = ({
  journey,
  isOutbound,
  isBottomCard,
  legsSeatInformation
}) => {
  const formatNumberOfChanges = (noOfChanges: number) => {
    switch (noOfChanges) {
      case 0:
        return 'direct';
      case 1:
        return '1 change';
      default:
        return noOfChanges + ' changes';
    }
  };

  const travelMessage = `${journey.duration}, ${formatNumberOfChanges(
    journey.numberOfChanges
  )}`;

  const flexStyle: Interpolation = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  };

  return (
    <div
      css={MQ({
        padding: ['0.75rem 1rem 1rem', '0.75rem 1.5rem 1rem'],
        background: COLORS.white,
        borderRadius: isBottomCard ? '0 0 8pt 8pt' : 0,
        borderTop: isOutbound ? 'none' : `1pt solid ${COLORS.mercury}`,
        color: COLORS.dune,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'row'
      })}
    >
      <div
        css={MQ({
          margin: ['0 0.5rem 0 0', '0 1.25rem 0 0']
        })}
      >
        <TicketInfoTrainIcon isOutbound={isOutbound} />
      </div>

      <div css={[FONTS.book, { width: '100%' }]}>
        <p
          css={{
            margin: '0.25rem 0 0.725rem',
            fontSize: '0.875rem',
            color: COLORS.dune
          }}
        >
          {journey.departDate}
        </p>

        <div>
          <div
            css={[
              FONTS.bold,
              flexStyle,
              MQ({
                fontSize: ['1rem', '1.25rem'],
                maxWidth: ['70%', '100%'],
                minWidth: ['12rem'],
                color: COLORS.dune
              })
            ]}
          >
            <p css={{ margin: 0 }}>{journey.departTime}</p>

            <TicketInfoArrowRight />

            <p
              css={MQ({
                margin: 0,
                width: ['5rem', '10rem']
              })}
            >
              {journey.arriveTime}
            </p>
          </div>

          <div
            css={[
              FONTS.book,
              flexStyle,
              MQ({
                fontSize: ['0.75rem', '0.875rem'],
                color: COLORS.doveGray,
                maxWidth: ['70%', '100%'],
                minWidth: '12rem',
                margin: '0.15rem 0 0.75rem'
              })
            ]}
          >
            <p
              css={MQ({
                margin: 0,
                width: ['5rem', '10rem'],
                minHeight: '1.75rem'
              })}
            >
              {isOutbound
                ? journey.originStationName
                : journey.destinationStationName}
            </p>
            <p
              css={MQ({
                margin: 0,
                width: ['5rem', '10rem']
              })}
            >
              {isOutbound
                ? journey.destinationStationName
                : journey.originStationName}
            </p>
          </div>
        </div>

        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            color: COLORS.dune,
            fontSize: '0.75rem',
            margin: '0rem 0.8rem 0.25rem 0',
            alignContent: 'center'
          }}
        >
          <div css={{ display: 'flex', flexDirection: 'row' }}>
            {journey.isLner ? <LnerLogo /> : null}
            <p css={{ margin: journey.isLner ? '0 0 0 0.5rem' : 0 }}>
              {travelMessage}
            </p>
          </div>
        </div>

        {legsSeatInformation.length !== 0 ? (
          <TicketInfoSharedSeats
            legsSeatInformation={legsSeatInformation}
            legs={journey.legs}
          />
        ) : null}
      </div>
    </div>
  );
};

export default TicketInfoBody;
