import React from 'react';
import FONTS from '../../styles/fonts';
import COLORS from '../../styles/colors';
import GooglePlayButton from './google-play-button';
import AppStoreButton from './app-store-button';
import '../../styles/font-face.css';

const AppStoresContainer: React.FC = () => (
  <>
    <p
      css={[
        FONTS.book,
        COLORS.dune,
        { marginLeft: '7px', paddingTop: '2rem', fontSize: '0.875rem' }
      ]}
    >
      For the best experience download the LNER app
    </p>
    <div css={{ display: 'flex', flexDirection: 'row' }}>
      <AppStoreButton />
      <GooglePlayButton />
    </div>
  </>
);

export default AppStoresContainer;
