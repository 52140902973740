import React from 'react';
import COLORS from '../styles/colors';
import WhatsNextBannerSeatImageSvg from '../icons/whats-next-banner-seat-image-svg';

const WhatsNextSeatImage: React.FC = () => (
  <div
    css={{
      padding: '0.6rem 0.9rem 0.4rem 1.1rem',
      fontSize: '12pt',
      background: COLORS.wildSand,
      borderRadius: '8pt',
      borderStyle: 'none',
      textAlign: 'center',
      margin: '1.25rem 0 1.5rem'
    }}
  >
    <WhatsNextBannerSeatImageSvg />
  </div>
);

export default WhatsNextSeatImage;
