const FONTS = {
  book: {
    fontFamily: 'Gotham-Book, Helvetica, Arial, sans-serif',
    fontWeight: 400
  },
  bold: {
    fontFamily: 'Gotham-Bold, Helvetica, Arial, sans-serif',
    fontWeight: 400
  },
  medium: {
    fontFamily: 'Gotham-Medium, Helvetica, Arial, sans-serif',
    fontWeight: 400
  },
  italic: {
    fontFamily: 'Gotham-Book, Helvetica, Arial, sans-serif',
    fontWeight: 400
  }
};

export default FONTS;
